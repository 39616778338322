.score {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col {
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid black;
  height: 65px;
  cursor: pointer;
}

.ship {
  max-width: 150px;
}

.ship-status {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.hit {
  max-width: 25px;
}

.cell-icon {
  max-width: 100%;
  max-height: 100%;
}